.Main{
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color:#E0F7FA;
}

.buttonWrapper{
    margin-bottom: 10px;
}
.carousel{
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 12px;
}